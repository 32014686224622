import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminData: {},
  isLoggedIn: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      const adminData = action.payload;
      state.adminData = adminData;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.adminData = {};
      state.isLoggedIn = false;
    },
  },
});

export const { setAdminData, login, logout } = adminSlice.actions;

export default adminSlice.reducer;
