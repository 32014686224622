import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./features/admin/adminSlice";
import userSlice from "./features/user/userSlice";

// Load state from local storage
const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem("jodhpurapp_redux");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading state from local storage:", error);
    return undefined;
  }
};

// Save state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem("jodhpurapp_redux", serializedState);
  } catch (error) {
    console.error("Error saving state to local storage:", error);
  }
};

// Load initial state
const preloadedState = loadState();

// Create store with preloaded state
export const store = configureStore({
  reducer: {
    admin: adminSlice,
    user: userSlice,
  },
  preloadedState,
});

// Subscribe to store changes to save state
store.subscribe(() => {
  saveState(store.getState());
});
